.container {
	border-right: 1px solid #cccccc;
	padding: 16px 8px 16px 0;
	width: 100%;
	height: 100%;

	.list {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		gap: 4px;

		.listItem {
			position: relative;
			height: 48px;
			width: 100%;

			border-radius: 4px;
			padding: 10px 8px 10px 10px;
			white-space: nowrap;

			text-decoration-line: none;

			display: grid;
			grid-template-columns: auto 1fr;
			gap: 16px;
			align-items: center;

			transition: all 150ms ease-in-out;

			cursor: pointer;

			img {
				width: 50px;
				height: 50px;
				fill: red;
			}

			&:hover {
				color: var(--blue-6) !important;
				.link {
					color: var(--blue-6) !important;
				}
				> svg path {
					stroke: var(--blue-6);
				}
			}

			&.active {
				color: var(--blue-6) !important;

				> svg path {
					stroke: var(--blue-6);
				}
			}

			.link {
                display: flex;
                justify-content: space-between;
                align-items: center;
				color: inherit;
				text-decoration: none;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: transparent;
					z-index: 1;
				}
			}
		}
	}

	.profileIcon {
	}

	.userLine {
		height: 48px;
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 16px;
		align-items: center;
		padding: 0 10px;
		cursor: pointer;
		white-space: nowrap;
		color: #333333 !important;
		text-decoration: none;

		.userName {
			display: flex;
			flex-direction: column;
			overflow: hidden;

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: 500;
				font-size: 16px;
				color: #0061af;
			}

			small {
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 14px;
				font-weight: 400;
				color: #959595;
				text-decoration: none;
			}
		}
	}

	.logoutItem {
		color: #ec1944 !important;

		&:hover {
			color: white;

			svg path {
				stroke: white;
			}
		}

		svg path {
			stroke: #ec1944;
		}
	}
}
