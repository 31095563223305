.card {
    text-decoration: none;
    color: var(--white);
    display: block;
    height: 100%;

    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);

    @media (hover: hover) {
        &:hover .description {
            max-height: 100%;
        }
    }
    @media (hover: none) {
        .description {
            max-height: 100%;
        }
    }
}

.figure {
    height: 100%;
    margin: 0;
    position: relative;

    .figcaption {
        background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgb(33, 33, 33, 0) 40%,
                rgb(33, 33, 33, 0.5) 75%,
                rgba(33, 33, 33, 0.8) 100%
        );
        padding: 32px 108px 32px 32px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @mixin smaller-than $mantine-breakpoint-xl {
            padding: 32px;
        }
    }
}

.mainImage {
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(0.8) contrast(1.2);
}

.title {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.8px;
    margin: 0;

    @mixin smaller-than $mantine-breakpoint-md {
        font-size: 28px;
        line-height: 40px;
    }
}

.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
}

.arrow {
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 32px;
    right: 32px;
    border-radius: 16px;
    border: 2px solid var(--white);
    backdrop-filter: blur(2px);

    width: 48px;
    height: 48px;

    svg {
        width: 32px;
        height: 32px;
    }

    @mixin smaller-than $mantine-breakpoint-xl {
        top: 32px;
        right: 32px;
    }
}
