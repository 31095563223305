.сontainer {
	width: 100%;
	max-width: $container-xs;
	margin: 0 auto;
    padding: 0 16px;
	/* TODO Add breakpoints for paddings*/

	/*@mixin larger-than $mantine-breakpoint-xs {*/
	/*	max-width: $container-sm;*/
    /*    padding: 0;*/
	/*}*/

	@mixin larger-than $mantine-breakpoint-sm {
		max-width: $container-md;
        padding: 0;
	}

	@mixin larger-than $mantine-breakpoint-md {
		max-width: $container-lg;
	}

	@mixin larger-than $mantine-breakpoint-lg {
		max-width: $container-xl;
	}

	@mixin larger-than $mantine-breakpoint-xl {
		max-width: $container-xxl;
	}
}

.fullWidth {
	max-width: 100% !important;
}
