.catalogButtonRoot {
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--Primary-Blue, #0061AF);
    padding: 12px 20px;

    .catalogButtonLabel {
        color: var(--Primary-White, #FFF);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
    }
}

.drawerOverlay {
    background: linear-gradient(to bottom, transparent 152px, #0009 152px);
}

.drawerInner {
    margin-top: 152px;
}

.drawerContent {
    height: 520px;
}

.drawerBody {
    padding: 0;
}

.contentContainer {
    display: grid;
    grid-template-columns: 300px 1fr;
    overflow: hidden;
    height: 520px;
    position: relative;

    &::after {
        content: "";
        display: block;
        height: 1px;
        background-color: #ECEFF4;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

.firstLevelCategories {
    padding: 16px 16px 24px 0;
}

.categoriesButtonRoot {
    border-radius: 8px;
    padding: 8px 12px;
    width: 100%;
}

.categoriesButtonLabel {
    margin-right: auto;
    color: #0A2432;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; /* 175% */
}

.categoriesButtonSection {
    &[data-position='left'] {
        margin-right: 8px;
    }

    &[data-position='right'] {
        margin-left: 8px;
    }
}

.allCategoriesButtonRoot {
    border-radius: 8px;
    padding: 8px 12px;
    overflow: hidden;
    width: 100%;
}

.allCategoriesButtonLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0061AF;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; /* 175% */
    margin-right: auto;
}

.secondLevelCategories {
    padding: 16px 0 48px 48px;
    border-left: 1px solid #ECEFF4;
}

.categoryTitle {
    color: #0A2432;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px; /* 140% */
    margin-bottom: 24px;
}

.categoryItems {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px 48px;
}

.subCategoryButtonRoot {
    border-radius: 8px;
    padding: 4px 12px;
    width: 100%;
}