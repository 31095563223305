.container {
    .desktop {
        display: block;
        @mixin smaller-than $mantine-breakpoint-md {
            display: none;
        }
    }

    .mobile {
        display: none;
        @mixin smaller-than $mantine-breakpoint-md {
            display: grid;
        }
    }
}


.desktop {
    padding: 20px 0!important;

    .headerTop {
        min-height: 44px;
        display: flex;
        align-items: center;
        gap: 32px;
        margin-bottom: 20px;
        max-height: 50px;

        .mainNavigation {
            margin-right: auto;
        }

        .languageCountryPicker {
        }
    }

    .headerBottom {
        height: 48px;
        display: flex;
        align-items: center;
        gap: 16px;

        .actions {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
}

.mobile {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 8px 16px;
    padding-top: 10px!important;
    padding-bottom: 20px!important;

    @mixin smaller-than 340px {
        gap: 4px;
    }

    .mobileLogo {
        margin-right: auto;
    }

    .mobileSearchForm {
        grid-area: 2 / 1 / 3 / 4;
    }
}