.root {
	padding-left: 0;
}

.label {
	color: #0061AF;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px; /* 175% */
}

.section[data-position='left'] {
	margin-left: 4px;
}