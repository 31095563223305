.header {
	display: flex;
	gap: 30px;
	align-items: flex-end;
	color: var(--primary-black);

	.title {
		flex-grow: 1;
		margin: 0;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
	}
	.link {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;
		font-weight: 600;
		line-height: 24px;
		color: var(--secondary-blue);
		white-space: nowrap;
		text-decoration: none;
	}

	@mixin smaller-than $mantine-breakpoint-md {
		display: none;
	}

}
