.footer {
	padding: 60px 0;
	background-color: var(--grey-9);

	@mixin smaller-than $mantine-breakpoint-sm {
		padding: 40px 0;
	}

	.footerColumns {
		display: flex;
		gap: 80px;

		@mixin smaller-than $mantine-breakpoint-md {
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@mixin smaller-than $mantine-breakpoint-sm {
			flex-direction: column;
			gap: 40px;
		}

		.footerColumn {
			display: flex;
			flex-direction: column;
			gap: 16px;

			@mixin smaller-than $mantine-breakpoint-md {
				flex: 1;
			}

			&.logoColumn {
				gap: 32px;
				flex-grow: 1;

				@mixin smaller-than $mantine-breakpoint-md {
					width: 100%;
					align-items: center;
					order: 9999;
					text-align: center;
					gap: 16px;
					margin-top: 32px;
				}

				.logoContainer {
					width: 100%;
					max-width: 360px;
					height: 148px;
				}
				.copyright {
					color: var(--grey-4);
					font-size: 14px;
					font-weight: 500;
					line-height: 24px;
					margin: 0;
				}
			}

			.columnTitle {
				font-size: 20px;
				font-weight: 500;
				line-height: 28px;
				color: var(--grey-0);
				margin: 0 0 16px 0;

				@mixin smaller-than $mantine-breakpoint-sm {
					margin: 0;
				}
			}

			.linkList {
				display: flex;
				flex-direction: column;
				gap: 16px;
				list-style-type: none;
				padding: 0;
				margin: 0;

				.link {
					position: relative;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					color: var(--grey-0);
					text-decoration: none;
					transition: color 0.3s;
					&:after {
						content: '';
						position: absolute;
						bottom: -5px;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: var(--grey-0);
						transition: opacity 0.25s;
						opacity: 0;
					}

					&:hover {
						color: var(--grey-0);

						&:after {
							opacity: 1;
						}
					}
				}
			}

			&.socialColumn {
				.contactList {
					display: flex;
					flex-direction: column;
					gap: 16px;
					list-style-type: none;
					padding: 0;
					margin: 0 0 12px 0;
					.contactItem {
						display: flex;
						gap: 16px;
						align-items: center;
						.contactText {
							display: flex;
							align-items: center;
							gap: 12px;
							font-size: 16px;
							font-weight: 400;
							line-height: 24px;
							color: var(--grey-0);
							text-decoration: none;
						}
					}
				}

				.socialList {
					max-width: 250px;
					display: flex;
					gap: 16px;
					list-style-type: none;
					padding: 0;
					margin: 0;
					.socialItem {
                        background-color: var(--grey-0);
                        border-radius: 50%;
                        overflow: hidden;
                        .socialLink{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 36px;
                            height: 36px;
                            transition: background-color 0.3s;
                            &:hover {
                                background-color: var(--grey-0);
                            }
                            .socialIcon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
					}
				}
			}
		}
	}
}
