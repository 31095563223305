.searchFormsWrapper {
	display: grid;
	grid-template-columns: auto auto 1fr auto auto;
	height: 48px;
	width: 100%;
	max-width: 600px;
	align-items: center;
	margin-right: auto;
	border-radius: 16px;
	border: 1px solid var(--Stroke-Gray, #DBE4F0);
	padding: 0 8px 0 20px;

	.divider {
		border-color: #CAD3DF;
		height: 24px;
		align-self: center;
	}

	.searchActionButton {
		width: 24px;
		height: 24px;
		min-width: 24px;
		min-height: 24px;
		border: none;
		color: #8493A8;

		&:hover {
			color: #0061AF;
		}
	}

	.searchForm {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.providerSelectRoot {
	width: 130px;

	.providerSelectInput {
		height: 46px;
		border: none;
		color: var(--Neutral-Black, #0A2432);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 171.429% */
	}
}

.textInputRoot {
	width: 100%;
}

.textInput {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 171.429% */
	color: #0A2432;

	&::placeholder {
		color: #8493A8;
	}

	&:-webkit-autofill {
		border: none;
		/* some other styles */
		/* background-color: white !important; */
		/* -webkit-text-fill-color: black !important; */
		box-shadow: 0 0 0 1000px white inset !important;
	}
}

.providerSelectSection {
	pointer-events: none;
}

.dropdown {
	box-shadow: 0 0 4px 0 rgba(7, 59, 102, 0.04), 0 1px 8px 0 rgba(7, 59, 102, 0.06);
}