.card {
    display: flex;
    align-items: center;
    gap: 20px;

    color: var(--primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    strong {
        color: var(--secondary-blue);
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
    }

    p {
        margin: 0;
    }

    @mixin smaller-than $mantine-breakpoint-md {
        flex-direction: column;
        align-items: center;
        gap: 12px;

        p {
            font-size: 14px;
            text-align: center;
        }

        strong {
            font-size: 20px;
        }
    }
}