.container {
	padding: 8px 16px;
	display: flex;
	flex-direction: column;
}

.root {
	padding: 8px 0 8px 12px;
}

.label {
	margin-right: auto;
	color: #0A2432;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px; /* 175% */
}
