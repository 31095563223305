.productWrapper {
	display: flex;
	gap: 40px;
	margin-top: 24px;
	width: 100%;

	.productRight {
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.services {
			display: flex;
			flex-direction: column;
			row-gap: 24px;

			.service {
				color: var(--primary-black);
				.title {
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
				}

				.description {
					font-size: 14px;
					line-height: 24px;
				}

				.alertRoot {
					margin: 0;
					padding: 0;
				}

				.alertMessage {
					font-size: 14px;
					line-height: 24px;
					color: var(--primary-black);
				}

				.alertIcon {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	.productImage {
		width: 50%;
	}

	@mixin smaller-than $mantine-breakpoint-lg {
		flex-direction: column;
		gap: 24px;

		.productImage {
			width: 100%;
		}
		.productRight {
			width: 100%;
		}
	}
}

.productTop {
	display: flex;
	flex-direction: column;
	gap: 24px;

	@mixin smaller-than $mantine-breakpoint-sm {
		gap: 16px;
	}
}

.productAdditional {
	width: 100%;
	margin-top: 80px;
	@mixin smaller-than $mantine-breakpoint-sm {
		margin-top: 48px;
	}
}

.productSection {
	padding: 32px;
	border-radius: 16px;
	background: var(--white);
	box-shadow: 0px 0px 4px 0px rgba(7, 59, 102, 0.04), 0px 0px 20px 0px rgba(7, 59, 102, 0.06);

	@mixin smaller-than $mantine-breakpoint-sm {
		padding: 24px;
	}
}