.container {
	display: flex;
	gap: 12px;
	position: sticky;
	top: 20px;


	.mainSlider {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;

		.mainSlide {
			display: flex;
			justify-content: center;
			width: 100%;

			.mainImage {
				object-fit: cover;
				border-radius: 10px;
				width: 100%;
				height: auto;
			}
		}
	}

	.sliderIndicator {
		padding: 4px 8px;
		border-radius: 200px;
		background: rgba(1, 1, 1, 0.20);
		height: auto;
		position: absolute;
		bottom: 16px;
		left: 24px;
		color: var(--white);
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;

		@mixin larger-than $mantine-breakpoint-md {
			display: none;
		}
	}

	.thumbnailCarousel {

		@mixin smaller-than $mantine-breakpoint-md {
			display: none;
		}

		.carouselSlide {
			display: flex;
			justify-content: center;

			.thumbnail {
				border: 2px solid transparent;
				border-radius: 8px;
				cursor: pointer;
				display: inline-flex;

				.thumbnailImage {
					object-fit: cover;
					border-radius: 8px;
				}

				&.activeThumbnail {
					border-color: #0a62ae;
				}
			}
		}
	}
}
