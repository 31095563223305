.productDescription {
	display: flex;
	flex-direction: column;
	gap: 20px;

	& img {
		width: 100%;
		height: auto;
	}
}

.title {
	font-size: 28px;
	font-weight: 500;
	line-height: 40px;
	margin: 0 0 32px;
}