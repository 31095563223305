.logo {
	position: relative;
	display: block;
	height: 100%;
    width: 100%;
	min-width: 82px;
	min-height: 30px;
	.logoImage {
		width: 100%;
		height: 100%;
		object-position: top;
	}
}
