.section {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 32px;
        margin-bottom: 48px;

        .headerText {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
        }

        .sectionTitle {
            border-radius: 8px;
            padding: 4px 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            background: #F4F9FD;
            color: #0061AF;
        }

        .sectionSubTitle {
            font-size: 40px;
            font-weight: 600;
            line-height: 60px;
            margin: 0;
        }

        .link {
            padding: 0;
        }

        @mixin smaller-than $mantine-breakpoint-md {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 32px;
            gap: 24px;

            .sectionSubTitle {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
            }
        }
    }

    .card {
        width: 228px;
        height: 228px;
        border-radius: 16px;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;

        .link {
            position: absolute;
            bottom: 10px;
            left: 8px;
            width: calc(100% - 36px);
            color: var(--Primary-White, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            text-decoration: none;
            padding: 8px 8px 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            border-radius: 16px;
            background: rgba(0, 97, 175, 0.50);
            backdrop-filter: blur(4px);

            .arrow {
                padding: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: 32px;
                right: 32px;
                border-radius: 16px;
                border: 2px solid var(--white);
                width: 48px;
                height: 48px;
            }
        }
    }
}