.drawerBody {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    height: 100%;
}


.catalogButton {
    border-radius: 16px;
    height: 48px;
    min-height: 48px;
    padding: 12px 24px;
    margin: 12px 16px;

    .catalogButtonSection[data-position='left'] {
        margin-right: 8px;
    }

    .catalogButtonLabel {
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px; /* 175% */
    }
}

.userMenuContainer {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
}

.loginButton {
    border-radius: 16px;
    height: 48px;
    min-height: 48px;
    padding: 10px 12px;
    margin: 8px 16px;

    .loginButtonSection[data-position='left'] {
        margin-right: 8px;
    }

    .loginButtonLabel {
        color: #0061AF;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px; /* 175% */
        margin-right: auto;
    }
}

.menuItemButtonRoot {
    padding: 8px 12px;
    height: 48px;

    .menuItemButtonLabel {
        color: #0A2432;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 175% */
        margin-right: auto;
    }
}

.exitItemButtonLabel {
    color: #E60033;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
    margin-right: auto;
}