.container {
    margin-top: auto;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;

    .buttonContainer {
        flex: 1;
    }

    .toggleButtonRoot {
        height: 44px;
        width: 100%;
        border-radius: 8px;
        background: #F4F9FD;
    }

    .toggleButtonSection[data-position='left'] {
        margin-right: 8px;
    }

    .toggleButtonLabel {
        color: var(--primary-blue-6-brand-color, #0061AF);
        font-size: 16px;
        font-weight: 500;
        line-height: 28px; /* 175% */
    }

    .selectWrapper {
        height: 0;
        overflow: hidden;
    }
}
.selectDropdown {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #ECEFF4;
    background: #FFF;
}