.root {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 80px;
}

.content {
    position: relative;
    padding-bottom: 50px;
    width: 100%;
}



.control {
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none!important;
    & > * {
        text-decoration: none!important;
    }
    button{
        margin-top: -15px;
    }
    &::after {
        z-index: -1;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background: linear-gradient(to top, white 50%, rgba(255, 255, 255, 0) 100%);
    }
}