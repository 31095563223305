.mobileWrapper {
    margin: 12px 0 0;
    background-size: cover;
    background-position: 40% 60%;
    background-repeat: no-repeat;

    @mixin larger-than $mantine-breakpoint-md {
        margin: 0 0 20px;
        background-image: none!important;
        background-position: center;
    }

    .desktopWrapper{
        border-radius: 24px;
        padding: 180px 120px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @mixin smaller-than $mantine-breakpoint-md {
            background-image: none!important;
            padding: 100px 0;
            min-height: 600px;
        }
    }


	.container {
        max-width: 540px;
        border-radius: 16px;
        padding: 32px;
        backdrop-filter: blur(20px);
        background-color: rgba(244, 249, 253, 0.2);
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        @mixin smaller-than $mantine-breakpoint-md {
            background-color: rgba(244, 249, 253, 0.3);
            display: flex;
            align-items: stretch;
            text-align: center;
        }



        .title {
            margin: 0;
            color: var(--grey-0);
            font-size: 40px;
            font-weight: 500;
            line-height: 60px;

            @mixin smaller-than $mantine-breakpoint-md {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
            }

        }

        .text {
            margin: 0 0 24px;
            color: var(--grey-0);
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;

            @mixin smaller-than $mantine-breakpoint-md {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .buttonRoot{
            padding: 10px 70px;
        }
	}
}


