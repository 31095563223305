.container{
    overflow-x: hidden;
}

.cabinetLayout {
    position: relative;
	display: flex;
	gap: 24px;
	.aside {
		flex: 1;
		max-width: 292px;
		@media all and (max-width: 992px) {
			display: none;
		}
	}
	.content {
		flex: 1;
		width: 100%;
	}
}
