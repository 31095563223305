.container {
    display: flex;
    align-items: center;
    gap: 20px;

    .progress {
        width: 100%;
        height: 4px;
    }

    .controls {
        display: flex;
        align-items: center;

        .arrowIcon {
            width: 48px;
            height: 48px;
            color: var(--secondary-grey);

            @media (hover: hover) {
                &:hover {
                    color: var(--secondary-blue);
                }
            }
            @media (hover: none) {
                &:active {
                    color: var(--secondary-blue);
                }
            }
        }
    }
}