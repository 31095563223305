.container {
	padding: 8px 16px;
	display: flex;
	flex-direction: column;
}

.root {
	padding: 8px 0 8px 12px;
}

.label {
	margin-right: auto;
	color: #0A2432;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px; /* 175% */
}

.allCategoriesButtonRoot {
    border-radius: 8px;
    padding: 8px;
    overflow: hidden;
    width: 100%;
}

.allCategoriesButtonLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0061AF;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; /* 175% */
    margin-right: auto;
}


.categoriesButtonSection {
    &[data-position='left'] {
        margin-right: 8px;
    }

    &[data-position='right'] {
        margin-left: 8px;
    }
}