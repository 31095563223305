.drawerHeader {
	padding: 8px 16px;
	position: sticky;
	background-color: #CDE0EF;

	.close {
		width: 48px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}