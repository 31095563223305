.container {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.charList {
		display: flex;
		flex-direction: column;
		gap: 12px;
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			display: grid;
			grid-template-columns: minmax(81px, 160px) 1fr;
			gap: 12px;
			&:not(:last-child)  {
				padding-bottom: 12px;
				border-bottom: 1px solid var(--blue-2);
			}
		}

		@mixin smaller-than $mantine-breakpoint-sm {
			gap: 8px;

			li {
				gap: 8px;
				grid-template-columns: minmax(81px, 128px) 1fr;
				.name {
					word-break: break-word;
				}
			}
		}
	}

	.sellerInfo {
		.sellerInfoTop {
			display: grid;
			grid-template-columns: 1fr auto;
			align-items: flex-start;
			gap: 24px;

			.sellerInfoTopItems {
				display: flex;
				flex-direction: column;
				gap: 12px;
			}
		}
		.sellerInfoItem {
			display: grid;
			gap: 12px;
			grid-template-columns: 160px 1fr;
		}

		.sellerInfoBottom {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 16px 28px;
		}

		@mixin smaller-than $mantine-breakpoint-sm {
			.sellerInfoTop {
				grid-template-columns: 1fr;
			}
			.sellerInfoBottom {
				grid-template-columns: 1fr;
			}
		}
	}

	.favouriteButton {
		border-radius: 16px;
		background-color: var(--blue-0);
		color: var(--secondary-blue);

		&.active {
			outline: 1px solid var(--secondary-blue);
			svg {
				fill: var(--secondary-blue);
			}
		}

		& span {
			font-size: 16px;
			font-weight: 500;
			line-height: 28px;
		}

		&:hover {
			background-color: var(--blue-1);
		}

		@mixin smaller-than $mantine-breakpoint-sm {
			width: 100%;
		}
	}

	.name {
		font-size: 16px;
		line-height: 28px;
		color: var(--grey-4);
		word-break: break-all;
	}

	.value {
		color: var(--primary-black);
		font-size: 16px;
		line-height: 28px;
	}
}


.accordionRoot {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.accordionControl {
	padding: 0;
}

.accordionLabel {
	color: var(--primary-black);
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	padding: 0;
}

.accordionItem {
	display: flex;
	flex-direction: column;
	gap: 28px;

	padding: 32px;
	border-radius: 16px;
	background: var(--white);
	box-shadow: 0px 0px 4px 0px rgba(7, 59, 102, 0.04), 0px 0px 20px 0px rgba(7, 59, 102, 0.06);

	@mixin smaller-than $mantine-breakpoint-sm {
		padding: 16px;
	}
}

.accordionContent {
	padding: 0;
}