.section {
    display: flex;
    flex-direction: column;
    gap: 48px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .header {
        display: flex;
        align-items: flex-end;
        gap: 32px;

        .title {
            flex: auto;
            font-size: 60px;
            font-style: normal;
            font-weight: 600;
            line-height: 84px;
            letter-spacing: -1.2px;
            margin: 0;
        }

        .button {
            padding: 12px 24px;
            font-size: 16px;
            color: var(--white);
            font-weight: 500;
            line-height: 24px;
            min-width: fit-content;
        }
    }

    .cardsContainer {
        .cards {
            height: 550px;
            display: flex;
            gap: 20px;

            li{
                flex: 1;
            }
        }

        .cardsCarousel {
            display: none;
        }

        @mixin smaller-than $mantine-breakpoint-lg {
            .cards {
                display: none;
            }
            .cardsCarousel {
                display: block;
            }
        }
    }


    .advantages {
        padding: 24px 48px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        align-items: center;
        border-radius: 16px;
        background: var(--white);
        box-shadow: 0 0 4px 0 rgba(7, 59, 102, 0.04), 0 0 20px 0 rgba(7, 59, 102, 0.06);

        @mixin smaller-than $mantine-breakpoint-lg {
            grid-template-columns: repeat(2, 1fr);
            padding: 24px;
            align-items: flex-start;
        }
    }

    @mixin smaller-than $mantine-breakpoint-md {
        gap: 32px;

        .header {
            flex-direction: column;
            gap: 24px;

            .title {
                font-size: 28px;
                line-height: 40px;
                text-align: center;
            }
            .button {
                width: 100%;
            }
        }
    }
}