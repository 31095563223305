.alert {
	border-radius: 12px;
	background: var(--white);
	box-shadow: 0px 0px 4px 0px rgba(7, 59, 102, 0.04), 0px 0px 20px 0px rgba(7, 59, 102, 0.06);

	.iconBlock {
		display: flex;
		gap: 12px;
		align-items: center;
		font-weight: 500;

		.icon {
			border-radius: 12px;
			background: var(--blue-0);
			padding: 8px;
			width: 40px;
			height: 40px;
		}
	}
	.updateIcon {
		display: flex;
		align-items: center;
		gap: 8px;
		color: var(--secondary-blue);
		font-weight: 500;

		padding: 0 24px 0 32px;
		border-left: 1px solid var(--blue-2);
	}

	.message {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;

		font-size: 16px;
		line-height: 28px;
		color: var(--primary-black);

		@mixin smaller-than $mantine-breakpoint-sm {
			font-size: 14px;
			line-height: 24px;

			.updateIcon {
				padding: 0 16px 0 24px;
			}
		}
	}
	&.translated {
		.message {
			cursor: pointer;

			&:hover {
				color: var(--secondary-blue);
			}
		}
	}
}
